@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

}


body {
  margin: 0;
  padding: 0;

}

.blue-yellow-hr {
  background: linear-gradient(to right, #0be4d1, yellow);
  height: 10px;
  border: none;
}

.yellow-blue-hr {
  background: linear-gradient(to right, yellow, #0be4d1);
  height: 10px;
  border: none;
}

.light-dark-hr {
  background: linear-gradient(to right, #d5ecfe, #34568b);
  height: 10px;
  border: none;
}

.dark-light-hr {
  background: linear-gradient(to right, #34568b, #d5ecfe);
  height: 10px;
  border: none;
}

.thank-hr {
  background: linear-gradient(60deg, #0066ff, #cb50e0, #ff6e80, #ffd455);
  height: 10px;
  border: none;
}

.you-hr {
  background: linear-gradient(60deg, #ffd455, #ff6e80, #cb50e0, #0066ff);
  height: 10px;
  border: none;
}



.blue-bright {
  background-image: linear-gradient(60deg,
      #000000,
      #00b2c2);
  height: 10px;
  border: none;
}

.bright-blue {
  background-image: linear-gradient(60deg,
      #00b2c2,
      #000000);
  height: 10px;
  border: none;
}

.hero-hr {
  background-image: linear-gradient(60deg,
      hsl(0deg 100% 41%) 0%,
      hsl(307deg 100% 36%) 23%,
      hsl(197deg 100% 50%) 43%,
      hsl(157deg 100% 65%) 62%,
      hsl(64deg 100% 48%) 81%,
      hsl(122deg 100% 50%) 100%);
  height: 12px;

}













/* Landing Page */

#landing {
  height: 100vh;
  width: 100%;
  overflow: hidden;

}

.landing_vid {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 0;
  object-fit: cover;
}

.landing-container {
  overflow: hidden;
}

#landing h1 {
  margin-top: 10px;
  color: #8BD9C7;
  font-size: 100px;
  animation-name: moveToLeft;
  animation-duration: 3s;

}

#landing h4 {
  padding-bottom: 15px;
  color: #f9f2ed;
  font-size: 35px;
  animation-name: moveToRight;
  animation-duration: 4s;
  animation-delay: 1.5s;
  animation-fill-mode: backwards;

}

#landing .landing-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.landing-text h4 {
  margin: 20px 0px;
}

#landing button {
  background-color: #8BD9C7;
  color: #000000;
  white-space: nowrap;
  width: 140px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  outline: none;
  transform: 0.2s;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  animation-name: animateBtn;
  animation-duration: 1s;
  animation-delay: 2.5s;
  animation-fill-mode: backwards;
}

#landing button:hover {
  background-color: #9ae044;
  color: #ffffff;
}

#landing button a {
  text-decoration: none;
}

@keyframes moveToLeft {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveToRight {
  0% {
    transform: translateY(-120px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animateBtn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Responsive */
@media (min-width: 300px) and (max-width:414px) {

  /* Landing */
  #landing {
    height: 100vh;
    width: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .landing_vid {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .landing-text h4 {
    margin: 20px 25px;
  }



  #landing h1 {
    font-size: 50px;
    padding: 0 30px;
  }

}

@media (min-width: 415px) and (max-width:700px) {
  #landing {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #000;
  }

  .landing_vid {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .landing-text h4 {
    margin: 10px 50px;
  }

  #landing h1 {
    font-size: 60px;
    padding: 0 30px;

  }
}

@media (min-width: 700px) and (max-width:1200px) {

  /* Landing */
  #landing {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    align-items: center;

  }

  .landing-text h4 {
    margin: 10px 50px;
  }

  .landing_vid {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 0;
    object-fit: cover;
  }

  #landing h1 {
    padding: 0 10px;

  }

}















/* Home */

#hero {
  background: url(images/hero.png);
  height: 98vh;
  width: 100%;
  background-size: cover;
  background-position: top 25% right 0;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-y: hidden;
}




#hero h1 {
  margin-top: 10px;
  color: #f9f2ed;
  font-size: 70px;
}

#hero p {
  color: #eee;
  font-size: 20px;
}

#hero .hero-text {
  margin-bottom: 18px;


}

#hero .span-gradient1 {
  background: linear-gradient(to right, #00C9FF,
      #92FE9D, #eedd44);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#hero .span-gradient2 {
  background: linear-gradient(to right, #eedd44,
      #92FE9D, #00C9FF);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


#hero .news-text {
  margin-bottom: 20px;
}

#hero .email_form {
  margin-right: 5px;
}

#hero input {
  height: 2.75rem;
  padding: 0 1.25rem;
  font-size: 14px;
  width: 350px;
  color: #eee;
  background-color: #606063;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

::placeholder {
  font-style: italic;
  font-size: 1.2em;
  color: mintcream;
  padding: 5px;
}

#hero button {
  color: #606063;
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 30px;
  color: #444444;
  background-color: #8cc0de;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  transform: 0.2s;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

#hero button:hover {
  background-color: #444444;
  color: #eee;
}



/* RESPONSIVE */


@media (min-width: 300px) and (max-width:413px) {


  /* Hero */
  #hero h1 {
    text-align: center;
  }

  #hero input {
    height: 2.75rem;
    padding: 0 1.25rem;
    font-size: 14px;
    width: 350px;
    color: #eee;
    background-color: #606063;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #hero .email_form {
    width: 310px;
    margin-bottom: 5px;
  }

  #hero {
    width: auto;
  }
}
















/* Concepts */
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
  width: 100%;
  padding: 100px 0;
}

.products>h1 {
  color: #eee;
  font-size: 50px;
}

.products>p {
  color: #eee;
  padding-bottom: 30px;
  font-size: 20px;
}

.all-cards {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: space-evenly;
}

.top>img {
  width: 100%;
}

.bottom {
  color: #eee;
  padding-top: 25px;
  font-size: 15px;
}

.all-cards>.card {
  padding: 25px 25px;

}

.card-img {
  width: 300px !important;
  height: 250px !important;
}






@media (min-width: 300px) and (max-width: 700px) {



  .all-cards {
    display: flex;
    flex-direction: column;
  }

  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    width: 100%;

  }
}

@media (min-width: 700px) and (max-width:1200px) {


  .all-cards {
    display: flex;
    flex-direction: column;
    ;
  }

  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    width: 100%;

  }

}

@media (min-width: 1201px) {
  .all-cards {
    display: flex;
    flex-direction: row;
  }
}













/* About Page */
.about-main {
  background-color: #eee;
  background: url(/src/images/reg1.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 80px 0;

}

.tech_title {
  text-align: center;
  color: #34568b;
  font-size: 50px;
  padding-bottom: 30px;
  padding-top: 40px
}

.main_block {
  margin: 0px 160px 60px 160px;

}

.tb2 {
  margin-bottom: 20px;
}

.tech_block {
  display: flex;
  padding: 100px 0px;
  justify-content: center;
  align-items: center;
  color: #34568b;

}

.tech_info {
  text-align: center;
  margin: 0px 25px;

}

.tech_info h5 {
  font-size: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.tech_info p {
  letter-spacing: 1px;
  line-height: 1.3;
  font-size: 20px;
  font-weight: 500;
  color: #34568b;
  line-height: 1.6;
}



.tech_photo img {
  width: 275px;
  height: auto;
}

.story_info>p {
  padding: 0 70px 30px 70px;
  line-height: 1.6;
}

/* Responsive */

@media (min-width: 300px) and (max-width:413px) {
  .about-main {
    background: url(/src/images/reg1.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 25%;

  }

  .main_block {
    margin: 30px 40px 0 40px;
    z-index: -22;
  }

  .tech_info h5 {
    font-size: 20px;
  }

  .tech_info p {
    line-height: 1.3;
    font-size: 15px;
    font-weight: 500;
  }

  .tech_photo img {
    width: 175px;
    height: auto;
  }

  .tech_pic1 {
    margin-bottom: 15px;
    margin-left: 10px;
  }

  .tech_pic2 {
    margin-top: 15px;
    margin-right: 10px;
  }

  .tech_block {
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    justify-content: center;
    align-items: center;

  }

  .tb3 {
    margin-top: 30px;
  }
}



@media (min-width: 413px) and (max-width: 700px) {
  .about-main {
    background: url(/src/images/reg1.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 25%;
  }

  .main_block {
    margin: 30px 40px 0 40px;

  }

  .tech_info h5 {
    font-size: 20px;
  }

  .tech_info p {
    line-height: 1.6;
    font-size: 15px;
    font-weight: 500;
  }

  .tech_photo img {
    width: 150px;
    height: auto;
  }

  .tech_pic1 {
    margin-bottom: 15px;
    margin-left: 10px;
  }

  .tech_pic2 {
    margin-top: 15px;
    margin-right: 10px;
  }

  .tech_block {
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    justify-content: center;
    align-items: center;
  }



  .tb3 {
    margin-top: 30px;
  }
}

@media (min-width: 700px) and (max-width:1200px) {
  .main_block {
    margin: 10px 125px 0px 125px;
  }

  .about-main {
    background: url(/src/images/reg1.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 25%;
  }
}

/* Survey */

.survey-container {
  color: #000000;
  background-color: #eee;
  background: url(/src/images/blobby.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  padding: 0 0 80px 0;

}

.title {
  padding-top: 50px;
  color: #fff;
  text-align: center;
  font-size: 40px;
}
.sub_title{

  color: #fff;
  text-align: center;
  font-size: 21px;
}

.form-main {
  background-color: #fff;
  max-width: 600px;
  margin: 20px auto;
  padding: 30px 20px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
}

.text-muted {
  font-size: 10px;
  font-weight: 500;
}

.sub-text {
  font-size: 15px;
  font-weight: 500;
}

.form-control {
  margin: 10px auto;
}

.input-box {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.area-box {
  padding: 10px;
  width: 100%;
  height: 100px;
}

.survey-btn {
  font-size: 14px;
  font-weight: 600;
  padding: 15px 30px;
  color: #444444;
  background-color: #8cc0de;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  transform: 0.2s;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.survey-btn:hover {
  background-color: #444444;
  color: #eee;
}

/* Responsive */

@media (min-width: 300px) and (max-width:413px) {
  .form-main {
    background-color: #fff;
    max-width: 600px;
    margin: 20px 10px;
    padding: 30px 20px;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
  }
}

@media (min-width: 414px) and (max-width:700px) {
  .form-main {
    background-color: #fff;
    max-width: 600px;
    margin: 20px 50px;
    padding: 30px 20px;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
  }
}

/* Thank You Page */

.thank-you {

  background: url(/src/images/color-blob.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 83vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

}

.thank-you>h1 {
  padding-bottom: 20px;
  color: #eee;
  font-size: 60px;
}

.thanks-btn {
  font-size: 14px;
  font-weight: 600;
  padding: 13px 30px;
  color: #000;
  background-color: #ffd455;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  transform: 0.2s;
  text-decoration: none;
  height: 45px;
}

.thanks-btn:hover {
  background-color: #cb50e0;
  color: #fff;

}

/* Responsive */
@media (min-width: 300px) and (max-width:413px) {
  .thank-you>h1 {
    padding: 0 30px;
    padding-bottom: 20px;
  }
}

@media (min-width: 414px) and (max-width:700px) {
  .thank-you>h1 {
    padding: 0 30px;
    padding-bottom: 20px;
  }
}