footer {
    background: url(/src/images/banner8.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #eee;
    height: auto;

}


.main-col {
    padding: 120px 100px;
    display: flex;
    flex-direction: column;


}

.top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;
}

.fa-brands {
    margin-left: 5px;
}


.col-1 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.col-2 {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.col-2 a {
    text-decoration: none;
    padding-bottom: 5px;
}

.col-3 {
    display: flex;
    flex-direction: column;
}

.icon-group {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.ig_footer {
    display: flex;
    align-items: baseline;
}

footer h4 {
    font-size: 18px;
    color: #eee;
    margin-bottom: 10px;
    color: #00b2c2;
}

footer p {
    font-size: 20px;
    margin: 0 0 8px 0;
    color: #eee;
}

footer a {
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    color: #eee;
    padding-bottom: 10px;
}

footer a:hover {
    transform: scale(1);
    color: #0be4d1
}

footer h6 {
    font-size: 18px;
    text-decoration: none;
    color: #eee;
    margin-bottom: 16px;

}

footer span {
    margin-right: 10px;
    color: #0be4d1;
}

.foot-icon {
    display: flex;
    flex-direction: column;
    width: 60px;
    margin-bottom: 20px;
}

.footer-group1 {
    display: flex;
    align-items: baseline;
}
footer input {
    height: 2.58rem;
    padding: 0 1.25rem;
    font-size: 14px;
    width: 20rem;
    color: #eee;
    background-color: #606063;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    margin-right: 5px;
}

::placeholder {
    font-style: italic;
    font-size: 1.2em;
    color: mintcream;
    padding: 5px;
}

footer button .normal {
    background-color: #ffd455;
    color: #eee;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    outline: none;
    transform: 0.2s;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

footer button:hover {
    background-color: #4d998f;
    color: #eee;
}

/* Responsive */
@media (min-width: 300px) and (max-width:413px) {

    footer p {
        font-size: 15px
    }

    footer h6 {
        font-size: 14px;
    }

    .call-action input {
        margin-bottom: 8px;
        width: 12rem;
        border-radius: 4px;
    }

    .fa-brands {
        display: none;
    }

    .main-col {
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
    }

    .icon-group {
        display: flex;
        margin-bottom: 5px;
    }

    .icon-group>h6 {
        margin-bottom: 0;
        margin-left: 5px;
    }

    .top-row {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 60px;
    }

    .icon {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

    }

    .col-2 {
        margin-right: 0;
        margin-left: 10px;
    }

    .col-1 {
        margin: 0 20px;
    }

}

@media (min-width: 414px) and (max-width: 500px) {
    .fa-brands {
        display: none;
    }
}

@media (min-width: 414px) and (max-width: 700px) {
    footer p {
        font-size: 15px
    }

    footer h6 {
        font-size: 14px;
    }

    .call-action input {
        margin-bottom: 8px;
        width: 15rem;
        border-radius: 4px;
    }



    .main-col {
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
    }

    .icon-group {
        display: flex;
        margin-bottom: 5px;
    }

    .icon-group>h6 {
        margin-bottom: 0;
        margin-left: 5px;
    }

    .top-row {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 60px;
    }

    .icon {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

    }

    .col-2 {
        margin-right: 0;
        margin-left: 10px;
    }

    .col-1 {
        margin: 0 20px;
    }
}

@media (min-width: 700px) and (max-width:1200px) {
    .icon {
        display: flex;
        flex-direction: row;
    }

    .icon-group {
        display: flex;
        margin-left: 10px;
    }

    .fa-brands {
        margin-right: 5px;
    
    }

    .col-1 h4 {
        margin-left: 10px;
    }

    .main-col {
        padding: 50px 50px;
        display: flex;
        flex-direction: column;
    }

    footer p {
        font-size: 18px;
    }

    footer h6 {
        font-size: 15px;
    }

    .normal {
        margin-top: 10px;
    }
}