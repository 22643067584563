@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

body {
    font-family: 'Montserrat', sans-serif;
}

header nav {
    display: flex;
    justify-content: space-between;
    padding: 20px
}
header {
    position: fixed;
    top: 0;
    width: 100%;
  }


.nav-main {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
}

.nav-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px
}

.logo {
    width: 55px;
    height: auto;
}

.nav-title {
    font-weight: 700;
    font-size: 21px;
    color: white;
    padding-left: 10px;
    text-decoration: none;
}

.nav-items>a {
    color: aliceblue;
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    position: relative;
    opacity: 0.9;
    font-weight: 900;
}

.nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: aliceblue;
    visibility: hidden;
    opacity: 0;
    font-size: 1rem;
}

/* link hover effects */
.nav-items>a:hover {
    opacity: 1;
}

.nav-items>a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background-color: aquamarine;
    transition: all .45s;
}

.nav-items>a:hover::before {
    width: 100%;
}

/* responsive */

@media only screen and (max-width: 700px) {
    header {
        display: flex;
        justify-content: space-between;
        padding: 10px
    }

    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: rgb(85, 207, 166);
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive_nav {
        transform: none;
    }

    header .responsive_nav>a {
        font-size: 30px;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a {
        font-size: 1.5rem;
        font-weight: 300;
    }

    .nav-main {
        padding-top: 0;
    }



    /* HERO MEDIA QUERY */
    #hero {
        width: 100%;
        height: 98vh;
        padding: 0 40px;
        background-position: top 30% right 30%;
    }

    .hero-text {
        display: flex;
        flex-direction: column;
        width: 100%;


    }

    .index_about {
        margin-left: 0px;
    }

    .section-b1 {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
    }

    #hero h4 {
        margin-top: 0px;
        padding-bottom: 20px;
        font-size: 35px;
    }

    #hero h1 {
        font-size: 65px;
    }

    #hero input {
        width: 275px;
        border-radius: 4px;
        margin-top: 0px;
    }
}

